import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Seo from "components/seo"
import Container from "components/container"
import Backlink from "components/legal-backlink"

const StyledLegalPatents = styled.section`
  padding: 60px 0;
  border-bottom-width: 0;

  ${breakpoint.medium`
    padding: 124px 0;
  `}

  h1 {
    font-size: 32px;
    text-align: center;
    margin: 0 auto 2rem;
    
      ${breakpoint.medium`
        font-size: 42px;
      `}
  }

  b {
		font-weight: 600;
  }

	h2 {
		font-weight: 600;
    font-size: 17px;
    ${breakpoint.medium`
      font-size: 21px;
    `}
  }
	
  .content {
    width: 100%;
    display: block;

  ${breakpoint.small`
	column-count: 2;
	column-gap: 2em;
  `}

    &:last-child {
      margin-bottom: 0;
    }

    p {
      width: 100%;
      margin-bottom: 20px;
    }
    
    ul {
      list-style: outside;
      margin-bottom: 20px;
      padding-left: 20px;
	  }
	
    li {
      margin-top: 5px;
      margin-bottom: 5px;
    }

.bufferText {
	min-height: 100px;
	
  ${breakpoint.small`
	min-height: 150px;
  `}

  ${breakpoint.medium`
	min-height: 200px;
  `}

}

    a {
      color: ${colors.orange};

      &:hover,
      &.active {
        color: ${colors.purpleDark};
      }
    }

    button {
      max-width: 320px;
      background-color: ${colors.orange};
      color: ${colors.charcoal};

      &:hover {
        background-color: ${colors.charcoal};
        color: ${colors.white};
      }
    }
  }
`

class Section extends React.Component {

  render = props => (
    <StyledLegalPatents>
      <Container>
        <h1>{this.props.title}</h1>
        {this.props.children}
      </Container>
    </StyledLegalPatents>
  )
}

class LegalPatents extends React.Component {
  render = () => (
    <React.Fragment>
      <Backlink pagetitle="Visby Patents" />
      <Section title="Visby Patents">
        <div className="content visible">
          <p>
            The following U.S. patents apply to the Visby products listed here. This list is posted on the Internet to provide notice to the
            public in compliance with the virtual patent marking provisions of 35
            USC 287. Updated: 06/05/2020
          </p>

          <p className="bufferText">
            Product: Visby Covid-19 Diagnostic
            <br />
            Patent Nos.: 8,263,392 | 10,124,334 | 10,052,629 | 10,456,783 |
            10,525,469 | D800,331
          </p>
        </div>
      </Section>
    </React.Fragment>
  )
}

export default LegalPatents

export const Head = () => (
  <Seo
        title="Visby Patents"
        description="Visby Patents"
        image="/meta/legal-visby-patents.jpg"
  />
)

